@font-face
  font-family 'Roboto Condensed'
  font-style normal
  font-weight 300
  src local(''), url('../fonts/roboto-condensed-v19-latin_cyrillic-300.woff2') format('woff2'), url('../fonts/roboto-condensed-v19-latin_cyrillic-300.woff') format('woff')
  font-display swap

@font-face
  font-family 'Roboto Condensed'
  font-style normal
  font-weight 400
  src local(''), url('../fonts/roboto-condensed-v19-latin_cyrillic-regular.woff2') format('woff2'), url('../fonts/roboto-condensed-v19-latin_cyrillic-regular.woff') format('woff')
  font-display swap

@font-face
  font-family 'Roboto Condensed'
  font-style normal
  font-weight 700
  src local(''), url('../fonts/roboto-condensed-v19-latin_cyrillic-700.woff2') format('woff2'), url('../fonts/roboto-condensed-v19-latin_cyrillic-700.woff') format('woff')
  font-display swap