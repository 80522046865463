@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: local(''), url("../fonts/roboto-condensed-v19-latin_cyrillic-300.woff2") format('woff2'), url("../fonts/roboto-condensed-v19-latin_cyrillic-300.woff") format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: local(''), url("../fonts/roboto-condensed-v19-latin_cyrillic-regular.woff2") format('woff2'), url("../fonts/roboto-condensed-v19-latin_cyrillic-regular.woff") format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: local(''), url("../fonts/roboto-condensed-v19-latin_cyrillic-700.woff2") format('woff2'), url("../fonts/roboto-condensed-v19-latin_cyrillic-700.woff") format('woff');
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  font: inherit;
  box-sizing: border-box;
  will-change: all;
}
*:before,
*:after {
  box-sizing: border-box;
  will-change: all;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-focus-ring-color: rgba(255,255,255,0);
}
body {
  font-size: 100%;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
a {
  background: transparent;
}
*:focus,
*:active,
*:hover {
  outline: 0;
}
hr {
  box-sizing: content-box;
  height: 0;
}
ol,
ul {
  list-style: none;
}
pre {
  tab-size: 4;
  white-space: pre-wrap;
}
q {
  quotes: '\201C' '\201D' '\2018' '\2019';
}
img {
  border: 0;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input {
  line-height: normal;
}
button,
select {
  text-transform: none;
}
button {
  overflow: visible;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"],
input[type="email"],
input[type="password"],
input[type="text"],
input[type="phone"] {
  -webkit-appearance: none;
  box-sizing: border-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
button,
input,
select[multiple],
textarea {
  background-image: none;
}
input,
select,
textarea {
  border-radius: 0;
  box-shadow: none;
}
input,
textarea {
  resize: none;
  user-select: text;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html {
  height: 100%;
}
.hide {
  display: none;
}
.hidden {
  overflow: hidden;
}
.clearfix {
  zoom: 1;
}
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
  visibility: hidden;
}
.nobr {
  white-space: nowrap;
}
.italic {
  font-style: italic;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}
.alignCenter {
  text-align: center;
}
a {
  text-decoration: none;
  color: inherit;
}
p {
  margin: 0;
}
img {
  display: block;
  max-width: 100%;
}
strong {
  font-weight: 600;
}
.popupLayer {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background: rgba(0,0,0,0.65);
}
.popup {
  display: none;
  position: fixed;
  z-index: 100;
  width: 100%;
  max-width: 68rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 5rem 2rem 5rem;
  box-shadow: 0 0.3rem 0.8rem 0 rgba(0,0,0,0.35);
}
@media (min-width: 480px) {
  .popup {
    padding: 5rem 5rem 5rem;
  }
}
@media (min-width: 920px) {
  .popup {
    width: auto;
  }
}
.popup__wrap {
  display: flex;
  flex-direction: column;
  gap: min(calc(0.8rem + 0.42vw), 1.6rem);
}
.popup__closer {
  position: absolute;
  z-index: 2;
  top: 1rem;
  right: 2rem;
  width: 5rem;
  height: 5rem;
}
.popup__closer:before,
.popup__closer:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 0.2rem;
  background: #24272b;
  border-radius: 0.2rem;
  transition: 0.3s background;
}
@media (hover: hover) and (min-width: 992px) {
  .popup__closer:hover:before,
  .popup__closer:hover:after {
    background: #fe3501;
  }
}
.popup__closer:before {
  transform: rotate(-45deg);
}
.popup__closer:after {
  transform: rotate(45deg);
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  color: #262626;
  font-size: min(calc(1.4rem + 0.05vw), 1.5rem);
  font-weight: 400;
  min-width: 32rem;
  height: 100%;
  font-family: "Open Sans", arial, helvetica, sans-serif;
}
.cursor-wait {
  cursor: wait;
}
.noscript {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 2rem;
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  line-height: 1.2;
  background-color: #fff;
  z-index: 999;
}
.btn {
  display: block;
  background-color: #c90;
  text-align: center;
  width: 100%;
  max-width: 20.2rem;
  height: 5rem;
  color: #fff;
  border: 0.2rem solid #c90;
  border-radius: 0.2rem;
  transition: 0.32s all;
  text-transform: uppercase;
  font-family: Gotham, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 0.064rem;
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
  padding: 1.6rem 1rem;
  text-shadow: 0 0 0.3rem #7e7e7e;
}
.btn.disabled,
.btn.disabled:hover {
  opacity: 0.3;
  background-color: #c90;
}
@media (hover: hover) and (min-width: 992px) {
  .btn:hover {
    background-color: rgba(0,0,0,0.65);
  }
}
.title {
  font-family: Gotham, Arial, sans-serif;
  font-size: min(calc(1.4rem + 1.46vw), 4.2rem);
  line-height: 1.4;
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}
@media (min-width: 1120px) {
  .title {
    font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  }
}
.callBtn {
  position: fixed;
  z-index: 99;
  right: 5%;
  bottom: 5%;
  border-radius: 50%;
  width: min(calc(4.4rem + 2.29vw), 8.8rem);
  height: min(calc(4.4rem + 2.29vw), 8.8rem);
  background: #fdde36 url("../images/phone.svg") no-repeat center center;
  background-size: 50% 50%;
  animation: callBtn 6s ease;
}
.header__box {
  max-width: 118rem;
  padding: 1.1rem 2rem 1rem;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  border-bottom: 1px solid #c9c9c9;
}
@media (min-width: 992px) {
  .header__box {
    align-items: center;
    padding: 1.1rem 3rem 1rem;
  }
}
.header__linkToggle {
  position: absolute;
  width: 4rem;
  height: 3rem;
  right: 2rem;
  top: min(calc(1.75rem + 0.91vw), 3.5rem);
  z-index: 4;
}
@media (min-width: 992px) {
  .header__linkToggle {
    display: none;
  }
}
.header__linkToggle:before,
.header__linkToggle:after {
  content: "";
}
.header__linkToggle:before,
.header__linkToggle:after,
.header__linkToggle span {
  position: absolute;
  height: 0.4rem;
  width: 100%;
  background: #262626;
  left: 0;
  border-radius: 0.2rem;
  transition: 0.3s all;
}
.header__linkToggle:before {
  top: 0;
}
.header__linkToggle:after {
  bottom: 0;
}
.header__linkToggle span {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header__linkToggle.open span {
  opacity: 0;
  width: 0;
}
.header__linkToggle.open:before {
  transform: rotate(-45deg) translate(-0.55rem, 1.3rem);
}
.header__linkToggle.open:after {
  transform: rotate(45deg) translate(-0.55rem, -1.25rem);
}
.header__logo {
  max-width: 16rem;
  flex-shrink: 0;
  padding: 1rem 0 0;
}
.header__nav {
  margin: 0 auto;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  transition: all 0.32s;
  transform: translate(0, -100%);
  background: #fff;
  opacity: 0;
  padding: 3.5rem 0;
  box-shadow: 0 0 2rem rgba(0,0,0,0.65);
}
@media (min-width: 992px) {
  .header__nav {
    position: relative;
    max-width: 49rem;
    opacity: 1;
    box-shadow: none;
    padding: 0;
    background: transparent;
    transform: none;
    transition: none;
  }
}
.header__nav.open {
  transform: translate(0, 0);
  opacity: 1;
}
.header__phone {
  display: none;
  align-items: center;
  border-right: 0.1rem solid #dfdfdf;
  padding: 0.6rem 3.2rem 0 0;
  height: 5.6rem;
}
@media (min-width: 1120px) {
  .header__phone {
    display: flex;
  }
}
.header__order {
  font-family: Gotham, Arial, sans-serif;
  font-size: min(calc(1.4rem + 0vw), 1.4rem);
  font-weight: 700;
  letter-spacing: 0.02rem;
  margin-left: 2.2rem;
  text-transform: uppercase;
  position: relative;
  display: none;
  max-width: 9rem;
  border: 0.2rem solid #ffd133;
}
@media (min-width: 1120px) {
  .header__order {
    display: block;
  }
}
body.home .header__box {
  border: none;
}
.nav {
  display: flex;
  flex-direction: column;
  gap: min(calc(2rem + 1.04vw), 4rem) min(calc(3rem + 1.56vw), 6rem);
  letter-spacing: 0.02rem;
  font-size: min(calc(1.4rem + 1.98vw), 5.2rem);
  font-weight: 600;
  text-align: center;
}
@media (min-width: 640px) {
  .nav {
    font-size: min(calc(1.4rem + 1.46vw), 4.2rem);
  }
}
@media (min-width: 992px) {
  .nav {
    justify-content: space-between;
    flex-direction: row;
    font-size: min(calc(1.4rem + 0vw), 1.4rem);
    text-align: left;
  }
}
.nav__item {
  position: relative;
}
.nav__item:before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(0, 0.6rem);
  height: 0.1rem;
  background: #262626;
  transition: 0.32s all;
  opacity: 0;
}
@media (hover: hover) and (min-width: 992px) {
  .nav__item:hover:before {
    transform: translate(0, 0.2rem);
    opacity: 1;
  }
}
.phone {
  text-align: right;
  font-weight: 600;
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  white-space: nowrap;
}
.phone__num {
  font-family: Gotham, Arial, sans-serif;
}
.phone__time {
  color: #9b9b9b;
  font-size: min(calc(1.4rem + -0.1vw), 1.2rem);
  line-height: 1.45;
}
.footer__box {
  padding: min(calc(2.25rem + 1.17vw), 4.5rem) 0 0;
  margin: 0 auto;
  max-width: 114rem;
  border-top: 1px solid #c9c9c9;
}
@media (min-width: 880px) {
  .footer__box {
    padding: min(calc(2.25rem + 1.17vw), 4.5rem) 0 min(calc(1.25rem + 0.65vw), 2.5rem);
  }
}
.form__wrap {
  position: relative;
  z-index: 9;
}
.form__box,
.form__answerSend,
.form__answerError,
.form__loader {
  position: relative;
  height: 100%;
  width: 100%;
}
.form__answerSend,
.form__answerError {
  display: none;
  font-weight: 600;
  text-align: center;
}
.form__answerError {
  color: #ff8080;
}
.form__title {
  text-align: center;
  font-weight: 700;
  font-size: min(calc(1.4rem + 1.77vw), 4.8rem);
  padding: 0 0 min(calc(1.9rem + 0.99vw), 3.8rem);
}
@media (min-width: 580px) {
  .form__title {
    font-size: min(calc(1.4rem + 0.94vw), 3.2rem);
  }
}
.form__subtitle {
  padding: 0 0 min(calc(1rem + 0.52vw), 2rem);
  text-align: center;
  font-size: min(calc(1.4rem + 0.31vw), 2rem);
}
.form__select {
  border: 1px solid #bdbdbd;
  cursor: pointer;
  appearance: none;
  position: relative;
}
.form__input,
.form__textarea {
  border: 1px solid #bdbdbd;
  width: 100%;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  padding: min(calc(1rem + 0.52vw), 2rem) min(calc(0.5rem + 0.26vw), 1rem);
  margin: 0 0 min(calc(1.4rem + 0.73vw), 2.8rem);
}
.form__input.error,
.form__textarea.error {
  border-color: #ff8080;
}
.form__input--hide,
.form__textarea--hide {
  display: none;
}
.form__textarea {
  padding: min(calc(0.5rem + 0.26vw), 1rem);
  min-height: 9.8rem;
  line-height: 1.5;
}
.form__accept {
  font-size: min(calc(1.4rem + -0.1vw), 1.2rem);
  color: #828282;
}
.form__accept a {
  text-decoration: underline;
}
.form__hide {
  display: none;
}
.form__submit {
  margin: min(calc(1.5rem + 0.78vw), 3rem) auto 0;
}
.form__loader {
  display: none;
  width: 28rem;
  height: 10rem;
  margin-left: auto;
  margin-right: auto;
}
.form__answerSend,
.form__answerError {
  padding: min(calc(1.5rem + 0.78vw), 3rem) 0 0;
}
.form__boxFile {
  position: relative;
  border: 1px solid #bdbdbd;
  margin: 0 0 min(calc(1.4rem + 0.73vw), 2.8rem);
}
.form__file {
  opacity: 0;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  padding: min(calc(1rem + 0.52vw), 2rem) min(calc(0.5rem + 0.26vw), 1rem);
}
.form__fileText {
  color: #828282;
  width: 100%;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  padding: min(calc(1rem + 0.52vw), 2rem) min(calc(0.5rem + 0.26vw), 1rem);
}
.imgLoader {
  position: absolute;
  width: 10rem;
  height: 10rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  stroke: #24272b;
}
.introduce__box {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  max-width: 118rem;
  margin: 0 auto;
}
@media (min-width: 640px) {
  .introduce__box {
    flex-direction: row;
  }
}
.introduce__slider {
  height: 100%;
}
.introduce__sliderWrap {
  height: min(calc(15rem + 7.81vw), 30rem);
}
@media (min-width: 640px) {
  .introduce__sliderWrap {
    height: min(calc(25rem + 13.02vw), 50rem);
  }
}
.introduce__left,
.introduce__right {
  width: 100%;
  position: relative;
  overflow: hidden;
}
@media (min-width: 640px) {
  .introduce__left,
  .introduce__right {
    width: 50%;
  }
}
@media (min-width: 1080px) {
  .introduce__left {
    width: 70%;
  }
}
.introduce__right {
  background-color: #3c3c3c;
}
@media (min-width: 1080px) {
  .introduce__right {
    width: 30%;
  }
}
.introduce__rightInner {
  padding: 3rem 2rem;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;
  opacity: 0;
  height: 100%;
  transform: translate(2rem, 0);
  transition: all 0.32s;
}
.introduce__rightInner--active {
  opacity: 1;
  transform: translate(0, 0);
}
.introduce__title {
  margin-bottom: 2.6rem;
  font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
  font-weight: 700;
  letter-spacing: 0.064rem;
  line-height: 1.32;
  max-width: 100rem;
  text-transform: uppercase;
  font-family: Gotham, Arial, sans-serif;
  text-shadow: 0 0 0.7rem rgba(0,0,0,0.65);
}
.introduce__text {
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 0 4rem;
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  line-height: 1.6;
  text-shadow: 0 0 0.5rem rgba(0,0,0,0.5);
}
.introduce__btnOrder {
  margin: 0 auto;
}
.benefits {
  padding: min(calc(5rem + 2.6vw), 10rem) 0 min(calc(2rem + 1.04vw), 4rem);
}
.benefits__box {
  width: 100%;
  max-width: 94rem;
  padding: 0 2rem;
  margin: 0 auto;
}
@media (min-width: 640px) {
  .benefits__box {
    padding: 0 2.5rem;
  }
}
.benefits__title {
  padding: 0 0 min(calc(2rem + 1.04vw), 4rem);
  text-align: center;
}
@media (min-width: 640px) {
  .benefits__title {
    padding: 0 0 min(calc(4rem + 2.08vw), 8rem);
  }
}
.benefits__items {
  display: flex;
  flex-wrap: wrap;
  gap: min(calc(2rem + 1.04vw), 4rem) 5.6%;
}
.benefits__item {
  width: 100%;
  padding-left: 6.4rem;
  position: relative;
  line-height: 1.6;
  min-height: 5rem;
  display: flex;
  align-items: center;
}
@media (min-width: 640px) {
  .benefits__item {
    padding-left: 8rem;
  }
}
@media (min-width: 720px) {
  .benefits__item {
    width: 47.2%;
    display: block;
  }
}
.benefits__ico {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 5rem;
}
@media (min-width: 720px) {
  .benefits__ico {
    top: 0;
    transform: none;
  }
}
.format {
  padding: min(calc(3.25rem + 1.69vw), 6.5rem) 0;
}
@media (min-width: 520px) {
  .format {
    padding: min(calc(2.55rem + 1.33vw), 5.1rem) 0 min(calc(3rem + 1.56vw), 6rem);
  }
}
.format__box {
  margin: 0 auto;
  max-width: 84.4rem;
  padding: 0 2rem;
  text-align: center;
}
.format__items {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 3rem;
  padding-top: min(calc(3.2rem + 1.67vw), 6.4rem);
}
@media (min-width: 520px) {
  .format__items {
    flex-direction: row;
  }
}
.format__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.format__ico {
  margin: 0 auto;
}
.format__name {
  padding: min(calc(0.5rem + 0.26vw), 1rem) 0 0;
  font-weight: 600;
}
.screens {
  padding: min(calc(5rem + 2.6vw), 10rem) 0;
}
@media (min-width: 992px) {
  .screens {
    padding: min(calc(3rem + 1.56vw), 6rem) 0;
  }
}
.screens__box {
  max-width: 114rem;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
}
.screens__title {
  padding-bottom: min(calc(2rem + 1.04vw), 4rem);
}
.screens__img {
  box-shadow: 0 0.1rem 0.3rem 0 rgba(0,0,0,0.3);
}
.screens__slider {
  padding: 0 3rem;
}
.slider {
  position: relative;
}
.slider__pagination {
  position: absolute;
  display: flex;
  gap: 0 0.8rem;
  bottom: 1rem;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, 0);
}
.slider__paginationItem {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  opacity: 0.35;
  background: #c90;
  cursor: pointer;
  transition: all 0.32s;
}
@media (min-width: 992px) {
  .slider__paginationItem:hover {
    transform: scale(1.25);
  }
}
.slider__paginationItem--active {
  opacity: 1;
  cursor: default;
}
@media (min-width: 992px) {
  .slider__paginationItem--active:hover {
    transform: scale(1);
  }
}
.slider__arr {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 50%;
  opacity: 0.3;
  transition: all 0.32s;
  transform: rotate(45deg);
  cursor: pointer;
  border: 2px solid #000;
  margin-top: -1rem;
}
.slider__arr--left {
  left: 0.4rem;
  border-top: 0;
  border-right: 0;
}
.slider__arr--right {
  right: 0.4rem;
  border-bottom: 0;
  border-left: 0;
}
@media (min-width: 992px) {
  .slider__arr:hover {
    opacity: 1;
    transform: rotate(45deg) scale(1.1);
  }
}
.slider__wrap {
  position: relative;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  touch-action: pan-y;
}
.slider__inner {
  display: flex;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  transition-property: transform;
}
.slider__item {
  flex-shrink: 0;
}
.slider__item--docs {
  width: 20rem;
  padding: 0.3rem;
}
.slider__img--cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.slider__img--docs {
  box-shadow: 0 0 0.3rem 0 rgba(0,0,0,0.3);
}
.services {
  padding: 6.5rem 0 9.4rem;
}
.services__box {
  width: 100%;
  max-width: 106rem;
  padding: 0 1.5rem;
  margin: 0 auto;
}
@media (min-width: 560px) {
  .services__box {
    padding: 0 2.5rem;
  }
}
.services__title {
  padding: 0 0 min(calc(2rem + 1.04vw), 4rem);
  text-align: center;
}
.services__items {
  display: flex;
  flex-wrap: wrap;
  gap: min(calc(4rem + 2.08vw), 8rem) 5%;
}
@media (min-width: 760px) {
  .services__items {
    gap: min(calc(3rem + 1.56vw), 6rem) 2%;
  }
}
@media (min-width: 992px) {
  .services__items {
    gap: min(calc(2rem + 1.04vw), 4rem) 1.5%;
  }
}
.services__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2.2rem 0;
  width: 100%;
  line-height: 1.6;
  transition: 0.32s all;
  font-size: min(calc(1.4rem + -0.05vw), 1.3rem);
  line-height: 1.7;
}
@media (min-width: 560px) {
  .services__item {
    width: 47.5%;
  }
}
@media (min-width: 760px) {
  .services__item {
    width: 32%;
  }
}
@media (min-width: 992px) {
  .services__item {
    width: 23.875%;
    padding: 1rem 1rem 1.2rem;
  }
}
@media (hover: hover) and (min-width: 992px) {
  .services__item:hover {
    background: #f2f2f2;
  }
}
.services__text {
  width: 100%;
}
.services__text li {
  position: relative;
  padding-left: min(calc(0.6rem + 0.31vw), 1.2rem);
}
.services__text li:before {
  content: "•";
  position: absolute;
  left: 0;
}
.services__name {
  display: block;
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  color: #262626;
  line-height: 1.4;
  padding: 0 0 min(calc(0.5rem + 0.26vw), 1rem);
}
@media (min-width: 992px) {
  .services__name {
    font-size: min(calc(1.4rem + 0.05vw), 1.5rem);
    line-height: 1.7;
    padding: 0;
  }
}
.services__img {
  flex-shrink: 0;
  box-shadow: 0 0.1rem 0.3rem 0 rgba(0,0,0,0.3);
  width: 100%;
}
@media (min-width: 560px) {
  .services__img {
    height: 145px;
    object-fit: cover;
  }
}
.contacts {
  display: flex;
  flex-wrap: wrap;
  gap: min(calc(2.75rem + 1.43vw), 5.5rem) 0;
}
.contacts__column {
  width: 100%;
}
@media (min-width: 880px) {
  .contacts__column {
    width: 50%;
  }
}
.contacts__column:nth-child(1) {
  padding: 0 min(calc(1.75rem + 0.91vw), 3.5rem) 0 min(calc(1rem + 0.52vw), 2rem);
}
.contacts__title {
  padding: min(calc(0.5rem + 0.26vw), 1rem) 0 min(calc(1.5rem + 0.78vw), 3rem);
}
.contacts__text {
  line-height: 1.6;
}
.contacts__email {
  text-decoration: underline;
}
@media (hover: hover) and (min-width: 992px) {
  .contacts__email:hover {
    text-decoration: none;
  }
}
.contacts__btnWrap {
  padding: min(calc(2.5rem + 1.3vw), 5rem) 0 0;
}
.contacts__map {
  width: 100%;
  height: min(calc(22.5rem + 11.72vw), 45rem);
}
@media (min-width: 880px) {
  .contacts__map {
    height: 35rem;
  }
}
.service {
  padding: min(calc(5rem + 2.6vw), 10rem) 0 min(calc(2.25rem + 1.17vw), 4.5rem);
}
.service__box {
  max-width: 114rem;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: min(calc(2.25rem + 1.17vw), 4.5rem) 2.5rem;
  margin: 0 auto;
}
.service__nav {
  padding: 0.5rem 0 2.5rem;
}
@media (min-width: 1120px) {
  .service__nav {
    width: 23rem;
    padding: 0.5rem 0 0;
  }
}
@media (min-width: 1120px) {
  .service__right {
    width: calc(100% - 25.5rem);
  }
}
.service__order {
  width: 100%;
}
.service__orderBtn {
  text-decoration: none !important;
  line-height: 1;
  margin: min(calc(1.75rem + 0.91vw), 3.5rem) auto;
}
.service__navSubTitle {
  display: block;
  text-transform: uppercase;
  font-size: min(calc(1.4rem + -0.1vw), 1.2rem);
  line-height: 1.6;
  font-family: Gotham, Arial, sans-serif;
  padding: 0.6rem 0 0;
}
.service__navTitle {
  display: inline-block;
  letter-spacing: 0.1rem;
  font-family: Gotham, Arial, sans-serif;
  text-transform: uppercase;
  font-size: min(calc(1.4rem + 1.46vw), 4.2rem);
  position: relative;
  border-bottom: 0.1rem dashed rgba(0,0,0,0.35);
}
@media (min-width: 1120px) {
  .service__navTitle {
    font-size: min(calc(1.4rem + 0.73vw), 2.8rem);
    border-bottom: none;
  }
}
.service__navTitle:before {
  content: "";
  position: absolute;
  right: -1.5rem;
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
  width: 0.8rem;
  height: 0.8rem;
  border: 0.1rem solid #262626;
  border-width: 0.1rem 0.1rem 0 0;
  transition: 0.32s all;
}
@media (min-width: 1120px) {
  .service__navTitle:before {
    display: none;
  }
}
.service__navTitle.open:before {
  transform: translate(0, -50%) rotate(135deg);
}
.service__navItems {
  display: none;
  flex-direction: column;
  gap: min(calc(1rem + 0.52vw), 2rem) 0;
  font-weight: 600;
  font-size: min(calc(1.4rem + -0.05vw), 1.3rem);
  line-height: 1.6;
  padding-top: min(calc(2.25rem + 1.17vw), 4.5rem);
}
.service__navItems.open {
  display: flex;
}
@media (min-width: 1120px) {
  .service__navItems {
    display: flex;
  }
}
.service__navItem {
  position: relative;
}
.service__navItem:before {
  content: "";
  position: absolute;
  left: -0.5rem;
  right: -0.5rem;
  top: -0.5rem;
  bottom: -0.5rem;
  background: #f2f2f2;
  opacity: 0;
  z-index: -1;
  transition: 0.32s all;
}
.service__navItem.current {
  color: #555;
  cursor: default;
}
.service__navItem.current:before {
  opacity: 1;
}
@media (hover: hover) and (min-width: 992px) {
  .service__navItem:hover:before {
    opacity: 1;
  }
}
.service__listImg,
.service__imgs {
  float: right;
  width: min(calc(5rem + 2.6vw), 10rem);
  padding: 0 0 4rem 4rem;
  display: flex;
  flex-direction: column;
  gap: min(calc(1rem + 0.52vw), 2rem) 0;
  padding: min(calc(1.7rem + 0.89vw), 3.4rem) 0 min(calc(1.4rem + 0.73vw), 2.8rem) min(calc(1rem + 0.52vw), 2rem);
  position: relative;
  z-index: 1;
}
@media (min-width: 580px) {
  .service__listImg,
  .service__imgs {
    width: min(calc(11rem + 5.73vw), 22rem);
    padding: min(calc(1.4rem + 0.73vw), 2.8rem) 0 min(calc(1.4rem + 0.73vw), 2.8rem) min(calc(2rem + 1.04vw), 4rem);
  }
}
.service__text {
  line-height: 1.6;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
}
.service__text--overflow {
  overflow: hidden;
  width: 100%;
  overflow-x: scroll;
  position: relative;
}
.service__text>* {
  margin-top: min(calc(1.6rem + 0.83vw), 3.2rem);
}
.service__text a {
  text-decoration: underline;
}
.service__text a:hover {
  text-decoration: none;
}
.service__text *+ul {
  margin-top: 0;
}
.service__text li {
  padding: 0.5rem 2.6rem;
  padding-right: 0;
}
.service__text ul li {
  position: relative;
}
.service__text ul li:before {
  content: "";
  position: absolute;
  border: solid 0.1rem #555;
  border-bottom: 0;
  border-left: 0;
  left: 1rem;
  top: 1.5rem;
  width: 0.6rem;
  height: 0.6rem;
  transform: rotate(45deg);
}
.service__text ol {
  list-style: inside decimal;
}
.service__text table {
  width: 100%;
}
.service__text td,
.service__text th {
  border: 0.1rem solid #aaa;
  padding: 0.2rem;
}
.service__text strong,
.service__text b {
  font-weight: 700;
}
.service__text .gray {
  background-color: #d9d9d9;
}
.service__text .yellow {
  background-color: #f6f490;
}
.service__text .name {
  font-weight: 600;
}
.service__text .span_plus {
  cursor: help;
  visibility: hidden;
  position: absolute;
  font-size: 11px;
  width: 200px;
  background-color: #fff;
  border-radius: 11px;
  padding: 7px 5px;
  margin-top: 18px;
  margin-left: -131px;
  box-shadow: 0 1px 8px #000;
}
.service__text .td_plus:hover .span_plus {
  visibility: visible;
}
.service__list {
  display: flex;
  flex-direction: column;
  padding-bottom: min(calc(1.25rem + 0.65vw), 2.5rem);
}
@media (min-width: 992px) {
  .service__list {
    padding-bottom: min(calc(1.75rem + 0.91vw), 3.5rem);
  }
}
.service__listItem {
  padding-top: min(calc(1.75rem + 0.91vw), 3.5rem);
}
@media (min-width: 992px) {
  .service__listItem {
    padding-top: min(calc(3.25rem + 1.69vw), 6.5rem);
  }
}
.service__listTitle {
  display: block;
  padding: 0 0 min(calc(0.4rem + 0.21vw), 0.8rem);
}
.service__certs {
  display: flex;
  gap: min(calc(3.4rem + 1.77vw), 6.8rem);
  flex-direction: column;
  text-align: center;
}
.service__certItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: min(calc(3.4rem + 1.77vw), 6.8rem) 5%;
}
@media (min-width: 992px) {
  .service__certItems {
    gap: min(calc(3.4rem + 1.77vw), 6.8rem) 3.33%;
  }
}
.service__certTitle,
.service__certItemTitle {
  font-weight: 700;
  font-size: min(calc(1.4rem + 0.21vw), 1.8rem);
  padding-bottom: min(calc(1.6rem + 0.83vw), 3.2rem);
}
.service__certItem {
  width: 100%;
}
@media (min-width: 660px) {
  .service__certItem {
    width: 47.5%;
  }
}
@media (min-width: 992px) {
  .service__certItem {
    width: 30%;
  }
}
.service__certItemText {
  line-height: 1.6;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
  text-decoration: underline;
}
.service__certItemImg {
  margin: 0 auto min(calc(1.2rem + 0.63vw), 2.4rem);
}
.service__certItemPrice {
  padding-top: min(calc(0.3rem + 0.16vw), 0.6rem);
  font-weight: 600;
  color: #f00;
}
@media (hover: hover) and (min-width: 992px) {
  .service__certItem:hover .service__certItemText {
    text-decoration: none;
  }
}
.pge {
  padding: min(calc(4.25rem + 2.21vw), 8.5rem) 2rem;
  max-width: 94rem;
  width: 100%;
  margin: 0 auto;
}
.pge__title {
  padding: 0 0 min(calc(2.25rem + 1.17vw), 4.5rem);
  text-align: center;
}
.text {
  display: flex;
  gap: min(calc(1.25rem + 0.65vw), 2.5rem) 0;
  flex-direction: column;
  line-height: 1.6;
  font-size: min(calc(1.4rem + 0.1vw), 1.6rem);
}
.text a {
  text-decoration: underline;
}
.text a:hover {
  text-decoration: none;
}
.text ul {
  list-style: inside disc;
}
.text ol {
  list-style: inside decimal;
}
.text b {
  font-weight: 600;
}
.fancybox-close-small {
  width: 6.6rem;
  height: 6.6rem;
}
@-moz-keyframes callBtn {
  88% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  92% {
    transform: scale(0.9);
  }
  94% {
    transform: scale(1.1);
  }
  96% {
    transform: scale(0.95);
  }
  98% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes callBtn {
  88% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  92% {
    transform: scale(0.9);
  }
  94% {
    transform: scale(1.1);
  }
  96% {
    transform: scale(0.95);
  }
  98% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes callBtn {
  88% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  92% {
    transform: scale(0.9);
  }
  94% {
    transform: scale(1.1);
  }
  96% {
    transform: scale(0.95);
  }
  98% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes callBtn {
  88% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.2);
  }
  92% {
    transform: scale(0.9);
  }
  94% {
    transform: scale(1.1);
  }
  96% {
    transform: scale(0.95);
  }
  98% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
