
/* POPUP */

.popupLayer
  display none
  position fixed
  left 0
  top 0
  width 100%
  height 100%
  z-index 99
  background rgba(0,0,0,.65)

.popup
  display none
  position fixed
  z-index 100
  width 100%
  max-width 68rem
  left 50%
  top 50%
  transform translate(-50%,-50%)
  background #fff
  padding 5rem 2rem 5rem
  box-shadow 0 .3rem .8rem 0 rgba(0,0,0,.35)

  @media (min-width: 480px)
    padding 5rem 5rem 5rem

  @media (min-width: 920px)
    width auto

  &__wrap
    display flex
    flex-direction column
    gap cw(16)

  &__closer
    position absolute
    z-index 2
    top: 1rem;
    right: 2rem;
    width 5rem
    height 5rem

    &:before,
    &:after
      content ""
      position absolute
      left 0
      top: 50%;
      width 100%
      height .2rem
      background #24272b
      border-radius .2rem
      transition .3s background

    &:hover:before,
    &:hover:after
      @media (hover: hover) and (min-width: $widthMobile)
        background #fe3501

    &:before
      transform rotate(-45deg)

    &:after
      transform rotate(45deg)