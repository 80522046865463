html
  font-size 62.5%
  scroll-behavior smooth

body
  color #262626
  fs(15)
  font-weight 400
  min-width 32rem
  height 100%
  font-family "Open Sans", arial, helvetica, sans-serif

.cursor-wait
  cursor wait

.noscript
  position fixed
  display flex
  justify-content center
  align-items center
  width 100%
  height 100%
  padding 0 2rem
  fs(18)
  line-height 1.2
  background-color #fff
  z-index 999

.btn
  display block
  background-color #C90
  text-align center
  width 100%
  max-width 20.2rem
  height 5rem
  color #fff
  border .2rem solid #C90
  border-radius .2rem
  transition .32s all
  text-transform uppercase
  font-family $fontGotham
  font-weight 700
  letter-spacing .064rem
  fs(14)
  padding 1.6rem 1rem
  text-shadow 0 0 0.3rem #7e7e7e

  &.disabled,
  &.disabled:hover
    opacity .3
    background-color #C90

  &:hover
    @media (hover: hover) and (min-width: $widthMobile)
      background-color rgba(0,0,0,.65)



.title
  font-family $fontGotham
  fs(42)
  line-height 1.4
  font-weight 700
  letter-spacing .1rem
  text-transform uppercase

  @media (min-width: 1120px)
    fs(28)

@keyframes callBtn
  88%
    transform scale(1)
  90%
    transform scale(1.2)
  92%
    transform scale(.9)
  94%
    transform scale(1.1)
  96%
    transform scale(.95)
  98%
    transform scale(1.05)
  100%
    transform scale(1)

.callBtn
  position fixed
  z-index 99
  right 5%
  bottom 5%
  border-radius 50%
  width cw(88)
  height cw(88)
  background #fdde36 url('../images/phone.svg') no-repeat center center
  background-size 50% 50%
  animation callBtn 6s ease






/* HEADER */

.header

  &__box
    max-width 118rem
    padding 1.1rem 2rem 1rem
    display flex
    justify-content space-between
    margin 0 auto
    border-bottom 1px solid #c9c9c9

    @media (min-width: $widthMobile)
      align-items center
      padding 1.1rem 3rem 1rem

  &__linkToggle
    position absolute
    width 4rem
    height 3rem
    right 2rem
    top cw(35)
    z-index 4

    @media (min-width: $widthMobile)
      display none

    &:before,
    &:after
      content ""

    &:before,
    &:after,
    span
      position absolute
      height .4rem
      width 100%
      background #262626
      left 0
      border-radius .2rem
      transition .3s all

    &:before
      top 0

    &:after
      bottom 0

    span
      top 50%
      left 50%
      transform translate(-50%, -50%)

    &.open span
      opacity 0
      width 0

    &.open:before
      transform rotate(-45deg) translate(-0.55rem, 1.3rem)

    &.open:after
      transform rotate(45deg) translate(-.55rem, -1.25rem)

  &__logo
    max-width 16rem
    flex-shrink 0
    padding 1rem 0 0

  &__nav
    margin 0 auto
    width 100%
    position absolute
    left 0
    right 0
    top 0
    z-index 3
    transition all .32s
    transform translate(0, -100%)
    background #fff
    opacity 0
    padding 3.5rem 0
    box-shadow 0 0 2rem rgba(0,0,0,.65)

    @media (min-width: $widthMobile)
      position relative
      max-width 49rem
      opacity 1
      box-shadow none
      padding 0
      background transparent
      transform none
      transition none

    &.open
      transform translate(0, 0)
      opacity 1

  &__phone
    display none
    align-items center
    border-right .1rem solid #dfdfdf
    padding .6rem 3.2rem 0 0
    height 5.6rem

    @media (min-width: 1120px)
      display flex

  &__order
    font-family $fontGotham
    fs(14)
    font-weight 700
    letter-spacing .02rem
    margin-left 2.2rem
    text-transform uppercase
    position relative
    display none
    max-width 9rem
    border .2rem solid #ffd133

    @media (min-width: 1120px)
      display block

body.home .header__box
  border none

.nav
  display flex
  flex-direction column
  gap cw(40) cw(60)
  letter-spacing .02rem
  fs(52)
  font-weight 600
  text-align center

  @media (min-width: 640px)
    fs(42)

  @media (min-width: $widthMobile)
    justify-content space-between
    flex-direction row
    fs(14)
    text-align left

  &__item
    position relative

    &:before
      position absolute
      content ""
      left 0
      right 0
      bottom 0
      transform translate(0, .6rem)
      height .1rem
      background #262626
      transition .32s all
      opacity 0

    &:hover:before
      @media (hover: hover) and (min-width: $widthMobile)
        transform translate(0, .2rem)
        opacity 1

.phone
  text-align right
  font-weight 600
  fs(18)
  white-space nowrap

  &__num
    font-family $fontGotham
  
  &__time
    color #9b9b9b
    fs(12)
    line-height 1.45







/* FOOTER */

.footer
  &__box
    padding cw(45) 0 0
    margin 0 auto
    max-width 114rem
    border-top 1px solid #c9c9c9

    @media (min-width: 880px)
      padding cw(45) 0 cw(25)





/* FORM */

.form
  &__wrap
    position relative
    z-index 9

  &__box,
  &__answerSend,
  &__answerError,
  &__loader
    position relative
    height 100%
    width 100%

  &__answerSend,
  &__answerError
    display none
    font-weight 600
    text-align center
    
  &__answerError
    color #ff8080
    
  &__title
    text-align center
    font-weight 700
    fs(48)
    padding 0 0 cw(38)

    @media (min-width: 580px)
      fs(32)

  &__subtitle
    padding 0 0 cw(20)
    text-align center
    fs(20)

  &__select
    border: 1px solid #BDBDBD
    cursor pointer
    appearance none
    position relative

  &__input,
  &__textarea
    border 1px solid #BDBDBD
    width 100%
    fs(16)
    padding cw(20) cw(10)
    margin 0 0 cw(28)

    &.error
      border-color #ff8080

    &--hide
      display none

  &__textarea
    padding cw(10)
    min-height 9.8rem
    line-height 1.5

  &__accept
    fs(12)
    color #828282

    a
      text-decoration underline

  &__hide
    display none
      
  &__submit
    margin cw(30) auto 0
    
  &__loader
    display none
    width 28rem
    height 10rem
    margin-left auto
    margin-right auto

  &__answerSend,
  &__answerError
    padding cw(30) 0 0

  &__boxFile
    position relative
    border 1px solid #BDBDBD
    margin 0 0 cw(28)

  &__file
    opacity 0
    position absolute
    z-index 2
    left 0
    top 0
    width 100%
    padding cw(20) cw(10)

  &__fileText
    color #828282
    width 100%
    fs(16)
    padding cw(20) cw(10)

.imgLoader
  position absolute
  width 10rem
  height 10rem
  left 50%
  top 50%
  transform translate(-50%,-50%)
  stroke #24272b




/* CONTENT */

.introduce

  &__box
    position relative
    z-index 2
    display flex
    flex-direction column
    max-width 118rem
    margin 0 auto

    @media (min-width: 640px)
      flex-direction row

  &__slider
    height 100%

  &__sliderWrap
    height cw(300)

    @media (min-width: 640px)
      height cw(500)

  &__left,
  &__right
    width 100%
    position relative
    overflow hidden

    @media (min-width: 640px)
      width 50%

  &__left
    @media (min-width: 1080px)
      width 70%

  &__right
    background-color #3c3c3c

    @media (min-width: 1080px)
      width 30%

    &Inner
      padding 3rem 2rem
      color #fff
      text-align: center
      display flex
      flex-direction column
      justify-content space-between
      position relative
      z-index 2
      opacity 0
      height 100%
      transform translate(2rem, 0)
      transition all .32s

      &--active
        opacity 1
        transform translate(0, 0)

  &__title
    margin-bottom 2.6rem
    fs(28)
    font-weight 700
    letter-spacing .064rem
    line-height 1.32
    max-width 100rem
    text-transform uppercase
    font-family $fontGotham
    text-shadow 0 0 .7rem rgba(0,0,0,.65);

  &__text
    max-width 50rem
    margin 0 auto
    padding 0 0 4rem
    fs(18)
    line-height 1.6
    text-shadow 0 0 .5rem rgba(0,0,0,.5);

  &__btnOrder
    margin 0 auto

.benefits
  padding cw(100) 0 cw(40)

  &__box
    width 100%
    max-width 94rem
    padding 0 2rem
    margin 0 auto

    @media (min-width: 640px)
      padding 0 2.5rem

  &__title
    padding 0 0 cw(40)
    text-align center

    @media (min-width: 640px)
      padding 0 0 cw(80)

  &__items
    display flex
    flex-wrap wrap
    gap cw(40) 5.6%

  &__item
    width 100%
    padding-left 6.4rem
    position relative
    line-height 1.6
    min-height 5rem
    display flex
    align-items center

    @media (min-width: 640px)
      padding-left 8rem

    @media (min-width: 720px)
      width 47.2%
      display block

  &__ico
    position absolute
    left 0
    top 50%
    transform translate(0, -50%)
    width 5rem

    @media (min-width: 720px)
      top 0
      transform none

.format
  padding cw(65) 0

  @media (min-width: 520px)
    padding cw(51) 0 cw(60)

  &__box
    margin 0 auto
    max-width 84.4rem
    padding 0 2rem
    text-align center

  &__items
    display flex
    justify-content space-around
    flex-direction column
    gap 3rem
    padding-top cw(64)

    @media (min-width: 520px)
      flex-direction row

  &__item
    display flex
    flex-direction column
    justify-content space-between

  &__ico
    margin 0 auto

  &__name
    padding cw(10) 0 0
    font-weight 600

.screens
  padding cw(100) 0

  @media (min-width: $widthMobile)
    padding cw(60) 0

  &__box
    max-width 114rem
    margin 0 auto
    padding 0 2rem
    text-align center

  &__title
    padding-bottom cw(40)

  &__img
    box-shadow 0 .1rem .3rem 0 rgba(0,0,0,.3)

  &__slider
    padding 0 3rem

.slider
  position relative

  &__pagination
    position absolute
    display flex
    gap 0 .8rem
    bottom 1rem
    left 50%
    z-index 2
    transform translate(-50%,0)

    &Item
      width 1.6rem
      height 1.6rem
      border-radius 50%
      opacity .35
      background #c90
      cursor pointer
      transition all .32s

      &:hover
        @media (min-width: $widthMobile)
          transform scale(1.25)

      &--active
        opacity 1
        cursor default

        &:hover
          @media (min-width: $widthMobile)
            transform scale(1)

  &__arr
    position absolute
    width 2rem
    height 2rem
    top 50%
    opacity .3
    transition all .32s
    transform rotate(45deg)
    cursor pointer
    border 2px solid #000
    margin-top -1rem

    &--left
      left .4rem
      border-top 0
      border-right 0

    &--right
      right .4rem
      border-bottom 0
      border-left 0

    &:hover
      @media (min-width: $widthMobile)
        opacity 1
        transform rotate(45deg) scale(1.1)

  &__wrap
    position relative
    z-index 1
    overflow hidden
    width 100%
    touch-action pan-y

  &__inner
    display flex
    box-sizing content-box
    width 100%
    height 100%
    transition-property transform

  &__item
    flex-shrink 0

    &--docs
      width 20rem
      padding .3rem

  &__img
    &--cover
      object-fit cover
      width 100%
      height: 100%;
      
    &--docs
      box-shadow 0 0 .3rem 0 rgba(0,0,0,.3)

.services
  padding 6.5rem 0 9.4rem

  &__box
    width 100%
    max-width 106rem
    padding 0 1.5rem
    margin 0 auto

    @media (min-width: 560px)
      padding 0 2.5rem

  &__title
    padding 0 0 cw(40)
    text-align center

  &__items
    display flex
    flex-wrap wrap
    gap cw(80) 5%

    @media (min-width: 760px)
      gap cw(60) 2%

    @media (min-width: $widthMobile)
      gap cw(40) 1.5%

  &__item
    display flex
    align-items center
    flex-direction column
    gap 2.2rem 0
    width 100%
    line-height 1.6
    transition .32s all
    fs(13)
    line-height 1.7

    @media (min-width: 560px)
      width 47.5%

    @media (min-width: 760px)
      width 32%

    @media (min-width: $widthMobile)
      width 23.875%
      padding 1rem 1rem 1.2rem

    &:hover
      @media (hover: hover) and (min-width: $widthMobile)
        background #f2f2f2

  &__text
    width 100%

    li
      position relative
      padding-left cw(12)

      &:before
        content "•"
        position absolute
        left 0

  &__name
    display block
    fs(18)
    color #262626
    line-height 1.4
    padding 0 0 cw(10)

    @media (min-width: $widthMobile)
      fs(15)
      line-height 1.7
      padding 0

  &__img
    flex-shrink 0
    box-shadow 0 .1rem .3rem 0 rgba(0,0,0,.3)
    width 100%

    @media (min-width: 560px)
      height 145px
      object-fit cover

.contacts
  display flex
  flex-wrap wrap
  gap cw(55) 0

  &__column
    width 100%

    @media (min-width: 880px)
      width 50%
    
    &:nth-child(1)
      padding 0 cw(35) 0 cw(20)

  &__title
    padding cw(10) 0 cw(30)

  &__text
    line-height 1.6

  &__email
    text-decoration underline

    &:hover
      @media (hover: hover) and (min-width: $widthMobile)
        text-decoration none

  &__btnWrap
    padding cw(50) 0 0

  &__map
    width 100%
    height cw(450)

    @media (min-width: 880px)
      height 35rem

.service
  padding cw(100) 0 cw(45)

  &__box
    max-width 114rem
    width 100%
    padding 0 2rem
    display flex
    flex-wrap wrap
    gap cw(45) 2.5rem
    margin 0 auto

  &__nav
    padding .5rem 0 2.5rem

    @media (min-width: 1120px)
      width 23rem
      padding .5rem 0 0

  &__right
    @media (min-width: 1120px)
      width calc(100% - 25.5rem)

  &__order
    width 100%

  &__orderBtn
    text-decoration none !important
    line-height 1
    margin cw(35) auto

  &__navSubTitle
    display block
    text-transform uppercase
    fs(12)
    line-height 1.6
    font-family $fontGotham
    padding .6rem 0 0

  &__navTitle
    display inline-block
    letter-spacing .1rem
    font-family $fontGotham
    text-transform uppercase
    fs(42)
    position relative
    border-bottom .1rem dashed rgba(0,0,0,.35)

    @media (min-width: 1120px)
      fs(28)
      border-bottom none

    &:before
      content ""
      position absolute
      right -1.5rem
      top 50%
      transform translate(0, -50%) rotate(45deg)
      width .8rem
      height .8rem
      border .1rem solid #262626
      border-width .1rem .1rem 0 0
      transition .32s all

      @media (min-width: 1120px)
        display none

    &.open:before
      transform translate(0, -50%) rotate(135deg)

  &__navItems
    display none
    flex-direction column
    gap cw(20) 0
    font-weight 600
    fs(13)
    line-height 1.6
    padding-top cw(45)

    &.open
      display flex

    @media (min-width: 1120px)
      display flex

  &__navItem
    position relative

    &:before
      content ""
      position absolute
      left -.5rem
      right -.5rem
      top -.5rem
      bottom -.5rem
      background #f2f2f2
      opacity 0
      z-index -1
      transition .32s all

    &.current
      color #555
      cursor default

    &.current:before
      opacity 1

    &:hover:before
      @media (hover: hover) and (min-width: $widthMobile)
        opacity 1

  &__listImg,
  &__imgs
    float right
    width cw(100)
    padding 0 0 4rem 4rem
    display flex
    flex-direction column
    gap cw(20) 0
    padding cw(34) 0 cw(28) cw(20)
    position relative
    z-index 1

    @media (min-width: 580px)
      width cw(220)
      padding cw(28) 0 cw(28) cw(40)

  &__text
    line-height 1.6
    fs(16)

    &--overflow
      overflow hidden
      width 100%
      overflow-x scroll
      position relative

    &>*
      margin-top cw(32)

    a
      text-decoration underline

      &:hover
        text-decoration none

    *+ul
      margin-top 0

    li
      padding .5rem 2.6rem
      padding-right 0

    ul
      li
        position relative

        &:before
          content ""
          position absolute
          border solid .1rem #555
          border-bottom 0
          border-left 0
          left 1rem
          top 1.5rem
          width .6rem
          height .6rem
          transform rotate(45deg)

    ol
      list-style inside decimal

    table
      width 100%

    td,th
      border .1rem solid #aaa;
      padding .2rem

    strong,b
      font-weight 700

    .gray
      background-color #D9D9D9

    .yellow
      background-color #F6F490

    .name
      font-weight 600

    .span_plus
      cursor help
      visibility hidden
      position absolute
      font-size 11px
      width 200px
      background-color white
      border-radius 11px
      padding 7px 5px
      margin-top 18px
      margin-left -131px
      box-shadow 0 1px 8px rgb(0 0 0 / 50%)

    .td_plus:hover .span_plus
      visibility visible

  &__list
    display flex
    flex-direction column
    padding-bottom cw(25)

    @media (min-width: $widthMobile)
      padding-bottom cw(35)

    &Item
      padding-top cw(35)

      @media (min-width: $widthMobile)
        padding-top cw(65)

  &__listTitle
    display block
    padding 0 0 cw(8)

  &__certs
    display flex
    gap cw(68)
    flex-direction column
    text-align: center

  &__certItems
    display: flex
    flex-wrap: wrap
    justify-content: center
    gap cw(68) 5% 

    @media (min-width: $widthMobile)
      gap cw(68) 3.33%

  &__certTitle,
  &__certItemTitle
    font-weight: 700
    fs(18)
    padding-bottom: cw(32)

  &__certItem
    width 100%

    @media (min-width: 660px)
      width 47.5%

    @media (min-width: $widthMobile)
      width 30%

    &Text
      line-height 1.6
      fs(16)
      text-decoration: underline

    &Img
      margin: 0 auto cw(24)

    &Price
      padding-top cw(6)
      font-weight: 600
      color red

    &:hover &Text
      @media (hover: hover) and (min-width: $widthMobile)
        text-decoration: none

.pge
  padding cw(85) 2rem
  max-width 94rem
  width 100%
  margin 0 auto

  &__title
    padding 0 0 cw(45)
    text-align center

.text
  display flex
  gap cw(25) 0
  flex-direction column
  line-height 1.6
  fs(16)

  a
    text-decoration underline

    &:hover
      text-decoration none

  ul
    list-style inside disc
  ol
    list-style inside decimal

  b
    font-weight 600

.fancybox-close-small
  width 6.6rem
  height 6.6rem